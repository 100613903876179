import React from 'react';
import "./style.css";
import GoogleMaps from '../GoogleMaps';
import bilde_hage from "../../images/oss_i_hagen.jpg";

function Info() {
    const locationNordstrandveien = {
        lat: 59.86124675009154,
        lng: 10.795105819440447,
        text: "Nordstrandveien 30, 1163 Oslo",
    };

    const locationVendomveien = {
        lat: 59.852144317435105,
        lng: 10.8083832,
        text: "Vendomveien 8, 1165 Oslo",
    };

    return (
        <div className="page-content informasjon">
            <br />
            <h1>Informasjon</h1>
            <br />
            <h3>Antrekk</h3>
            <p>Kle deg til fest, hva enn det betyr for deg! Sommerkjole, dress eller rustning. Alt er lov, så lenge du ikke kommer i hvit kjole 😉</p>
            <hr />
            <br />
            <h3>Taler</h3>
            <p>Dersom du har lyst til å si noen mer eller mindre velvalgte ord under middagen, så ta kontakt med våre toastmastere Isabelle Hvattum (+47 481 24 002) og Ruben Heimset (+47 906 32 539).
            </p>
            <hr />
            <br />
            <h3>Allergier</h3>
            <p>Dersom du har noen allergier eller andre behov med tanke på mat og drikke, så gi beskjed til Kjersti (+47 479 03 911) eller Gaute (+47 473 91 312)</p>
            <hr />
            <br />
            <h3>Lokale</h3>
            <p>Vi skal være på Nordstrandhuset, adresse Nordstrandveien 30. Vielsen foregår i hagen med påfølgende middag og fest inne på huset.</p>
            <hr />
            <br />
            <h4>Adkomst</h4>
            <p>Parkesingsplassene på huset forbeholdes catering og personale, så det er begrenset med parkeringsplasser rett ved lokalet.
                Nordstrandhuset ligger rett ved trikkeholdeplassen Sæter (linje 13 og 19), og derfra går det også nattbuss til Oslo sentrum.</p>
            <br />

            <GoogleMaps zoom={15} pins={[locationNordstrandveien]} />
            <hr />
            <br />

            <h4>Lunsj søndag 25. juli</h4>
            <p>Det serveres kaffe og kakerester i hagen til foreldrene til Kjersti dagen etterpå, i Vendomveien 8.
                Nærliggende kollektivmuligheter er Ljabru holdeplass for både trikk (linje 13 og 19) og buss (linje 79)</p>
            <br />

            <GoogleMaps zoom={15} pins={[locationVendomveien, locationNordstrandveien]} 
            />
            <div className="info-bilder">
                <img src={bilde_hage} alt="Kjersti og Gaute befarer uteområdet på Nordstrandhuset" />
            </div>

        </div>
    );
}

export default Info;
