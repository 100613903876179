import React from 'react';
import "./style.css";

import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import PetsIcon from '@mui/icons-material/Pets';

function TimelineElement({time, text, last=false}) {

    return (
        <TimelineItem>
            <TimelineSeparator>
                <TimelineDot className="timeline-dot">
                    <PetsIcon className="timeline-icon" />
                </TimelineDot>
                {!last && <TimelineConnector className="timeline-line" />}
            </TimelineSeparator>
            <TimelineContent sx={{"fontFamily": "'Open Sans', sans-serif"}}>
                <b>{time}</b>
                <br/>
                {text}
                <br/>
                <br/>
            </TimelineContent>
        </TimelineItem>
    );
}

export default TimelineElement;
