import React from 'react';
import "./style.css";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Songs() {
    const [expanded, setExpanded] = React.useState("panel1");

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className="page-content songs-page">
            <br />
            <h1>Sang</h1>
            <br />
            <br />
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <div>
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            SveenLundePott
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>
                            Hans Øyvind Lunde
                        </Typography>

                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <p>Melodi: Tre nøtter til Askepott</p>
                    <br />
                    <Typography>Kjersti og Gaute har giftet seg nå</Typography>
                    <Typography>Dagen er faktisk kommet </Typography>
                    <Typography>Bunad og skjorter og håret må stå </Typography>
                    <Typography>Maten og vinen flommet </Typography>
                    <Typography>Du for en heldig mann</Typography>
                    <Typography>Over i ektestand </Typography>
                    <Typography>Huset der har de jo alt flyttet inn</Typography>
                    <Typography>Kopper og katter der har de hver sin</Typography>
                    <Typography>Og Baobao Baobao og Mala </Typography>
                    <Typography>Baobao Baobao og Mala</Typography>
                    <Typography>Kattene ville jo også vært med men</Typography>
                    <Typography>Da ville duken og pynten falt ned på</Typography>
                    <Typography>Baobao Baobao og Mala</Typography>
                    <Typography>Midt i bryllups tala.</Typography>
                    <br />
                    <Typography>Gjestene ankom og alt er gått fint</Typography>
                    <Typography>Regnet har holdt seg borte </Typography>
                    <Typography>Solen den skinner og været er varmt </Typography>
                    <Typography>Alle vil gå i skjorte </Typography>
                    <Typography>Bruden hun smiler glad </Typography>
                    <Typography>Brudgommen er så bra</Typography>
                    <Typography>Bunader kjoler og dresser i fleng </Typography>
                    <Typography>Svinger seg sammen i Guds kløvereng </Typography>
                    <Typography>Og maten maten praten</Typography>
                    <Typography>Høres ut til gaten </Typography>
                    <Typography>Snart reiser Kjersti og Gaute sin vei </Typography>
                    <Typography>Godt var det jammen at ingen sa nei.</Typography>
                    <Typography>For nå skal nytt liv begynne</Typography>
                    <Typography>Samme rolig lynne</Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default Songs;
