import { Icon } from '@iconify/react';
import locationIcon from '@iconify/icons-mdi/map-marker';
import "./style.css";

function LocationPin({ text }) {
    return (
        <div className="pin" title={text}>
            <Icon icon={locationIcon} className="pin-icon" />
        </div>
    );
}

export default LocationPin;