import React from 'react';
import "./style.css";
import GoogleMapReact from 'google-map-react';
import LocationPin from './LocationPin';

function GoogleMaps({pins, zoom}) {

    return (
        <div className="maps-div">
            <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
                defaultCenter={{lat: pins[0].lat, lng: pins[0].lng}}
                defaultZoom={zoom}
            >
                {pins.map(({lat, lng, text}) => 
                    <LocationPin
                        key={lat * 100 + lng}
                        lat={lat}
                        lng={lng}
                        text={text}
                    />
                )}
            </GoogleMapReact>
        </div>
    );
}

export default GoogleMaps;
