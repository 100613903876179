import { React, useRef } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import "./style.css";
import { Link } from "react-router-dom";
import logo from "../../logo.svg";

function Navbar() {
    const navRef = useRef();

    const showNavbar = () => {
        navRef.current.classList.toggle("responsive_nav");
    }

    return (
        <header className="navbar-outer">
            <img src={logo} alt="logo" className="navbar-icon"></img>
            <nav ref={navRef}>
                <Link onClick={showNavbar} to="/">Forside</Link>
                <Link onClick={showNavbar} to="/dagsplan">Om dagen</Link>
                <Link onClick={showNavbar} to="/info">Informasjon</Link>
                <Link onClick={showNavbar} to="/bryllupsliste">Bryllupsliste</Link>
                <Link onClick={showNavbar} to="/bilder">Bilder</Link>
                <button className="nav-btn nav-close-btn" onClick={showNavbar}>
                    <MenuIcon />
                </button>
            </nav>
            <button className="nav-btn" onClick={showNavbar}>
                <MenuIcon />
            </button>
        </header>
    );
}

export default Navbar;