import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from "./components/Forside";
import Navbar from './components/Navbar';
import Whishlist from './components/Whishlist';
import Info from './components/Info';
import Dagsplan from './components/Dagsplan';
import ImagePage from './components/ImagePage';
import Songs from './components/Songs';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <div className="content">
          <aside className="sidebar sidebar-left" />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/dagsplan" element={<Dagsplan />} />
            <Route path="/bryllupsliste" element={<Whishlist />} />
            <Route path="/info" element={<Info />} />
            <Route path="/bilder" element={<ImagePage />} />
            <Route path="/sang" element={<Songs />} />
          </Routes>
          <aside className="sidebar sidebar-right" />
        </div>
      </div>
    </Router>
  );
}

export default App;
