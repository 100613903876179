import React from 'react';
import "./style.css";
import polaroid_1 from "../../images/polaroid_1.jpg";
import polaroid_2 from "../../images/polaroid_2.jpg";
import YoutubeEmbed from './YoutubeEmbed';

function ImagePage() {

    return (
        <div className="page-content image-page">
            <br />
            <h1>Takk for dagen!</h1>
            <YoutubeEmbed embedId="ocKMk_Rflc4" />
            <p>Stor takk til Kjersti Valen som laget denne fantastiske videoen!</p>
            <br />
            <br />
            <p>Dersom du har noen bilder fra den store dagen, setter vi veldig stor pris på om du har lyst til å dele dem med oss!</p>
            <p>De kan lastes opp i Google Foto-albumet med denne lenken:</p>
            <br />
            <br />
            <a href="https://photos.app.goo.gl/zyP3fMcsCRZsV4Ln6" target="_blank" rel="noopener noreferrer">
                <h2 className="wrap-link">
                    https://photos.app.goo.gl/zyP3fMcsCRZsV4Ln6
                </h2>
            </a>
            <br />
            <br />
            <div className="image-bilder">
                <img src={polaroid_1} alt="Polaroidbilde av Kjersti og Gaute i finstas" />
                <img src={polaroid_2} alt="Polaroidbilde av Kjersti og Gaute i Kina" />
            </div>
        </div>
    );
}

export default ImagePage;
