import React from 'react';
import bilde_stroop from "../../images/oss_stroop.heic";

function Whishlist() {

    return (
        <div className="page-content">
            <br />
            <h1>Bryllupsliste</h1>
            <br />
            <p>Vi ønsker oss hovedsaklig god stemning og godt humør!</p>
            <p>Ellers ønsker vi oss bidrag til bryllupsreise, dette kan gjøres via Vipps eller til kontonummer 81014823514.</p>
            <p>Vi er også registrert hos Kitchn her:</p>
            <br />
            <a href="https://www.kitchn.no/onskeliste/140544" target="_blank" rel="noreferrer" ><p>www.kitchn.no/onskeliste/140544</p></a>
            <br />
            <h2>Andre ønsker</h2>
            <p>Vi har også noen ønsker fra andre nettbutikker, men de har ikke integrert ønskeliste. Det er fint om du sjekker med forlover Sofie Lie Rapp (+47 483 58 544) før du eventuelt kjøper, så hun kan sjekke at gaven ikke er kjøpt fra før.</p>
            
            <ul>
                <li><a href="https://www.skarpekniver.com/magnetliste-25cm-amerikansk-valnott.html?gclid=CjwKCAiAu5agBhBzEiwAdiR5tI7ljdyW8gTc3_HezULAFY5sWQOmwMHRjSX3FvjuSCXQeZASRXRV1xoC32oQAvD_BwE" target="_blank" rel="noreferrer" >
                    Magnetlist til kniver
                </a></li>
                <li><a href="https://www.helsport.com/no/telt-og-tarp/3-sesong/rondane-superlight-3-camp/?item=163-014-V" target="_blank" rel="noreferrer" >
                    Ekstra lett telt til fjelltur
                </a></li>
                <li><a href="https://www.fjellsport.no/turutstyr/soveposer/liggeunderlag/mountain-equipment-aerostat-synthetic-7-0-mat-long-ombre-blue" target="_blank" rel="noreferrer" >
                    Oppblåsbare liggeunderlag (to stykk)
                </a></li>
                <li>
                    Philips hue rgb GU10 lyspærer (8 stykk). De selges i&nbsp;
                    <a href="https://www.power.no/smarte-hjem/smart-belysning/smarte-lyspaerer/philips-hue-wca-smart-paere-43w-gu10/p-1198714/store/1130/?gclid=CjwKCAiAu5agBhBzEiwAdiR5tLJK3DoG6T3t_JlT-l2Qd_bEzHQ73lf3Lvue6lGokq6oXuzK9kESTxoC0Z4QAvD_BwE&gclsrc=aw.ds" target="_blank" rel="noreferrer">
                        pakning på 1
                    </a>,&nbsp;
                    <a href="https://www.power.no/smarte-hjem/smart-belysning/smarte-lyspaerer/philips-hue-wca-smartpaere-gu10-bt-43w-2stk/p-1198715/store/?gclid=CjwKCAiAu5agBhBzEiwAdiR5tIdKrk4dwPD7saJw92jZzf3Nww7ZyRl-ybC6RSxaxMxDGwbIz9ruIBoC19QQAvD_BwE&gclsrc=aw.ds" target="_blank" rel="noreferrer">
                        pakning på 2
                    </a> og&nbsp;
                    <a href="https://www.power.no/smarte-hjem/smart-belysning/smarte-lyspaerer/philips-hue-wca-smartpaere-43w-gu10-3pk/p-1202658/store/1136/?gclid=CjwKCAiAu5agBhBzEiwAdiR5tGGFYdIjoH4KCwd8oI9v_u73wKltoMw-m2MxWGMjaCK9GqBrlfN8xxoCefkQAvD_BwE&gclsrc=aw.ds" target="_blank" rel="noreferrer">
                        pakning på tre
                    </a>.
                </li>
            </ul>

            <div className="info-bilder">
                <img src={bilde_stroop} alt="Kjersti og Gaute spiser Stroopwafel i Amsterdam" />
            </div>
        </div>
    );
}

export default Whishlist;
