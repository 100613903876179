import React from 'react';
import "./style.css";

function Forside() {

    return (
        <div className="forside">
            <br/>
            <br/>
            <h3 className="forside-dato">24. JUNI 2023</h3>
            <p className="forside-overskrift">Kjersti og Gaute</p>
            <h3 className="forside-dato forside-undertekst">Nordstrandhuset, Oslo</h3>
        </div>
    );
}

export default Forside;
