import React from 'react';
import "./style.css";
import Timeline from '@mui/lab/Timeline';
import TimelineElement from './TimelineElement';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import bilde_boba from "../../images/oss_bubbletea.jpg";

export default function Dagsplan() {
  return (
    <div className="page-content dagsplan">
        <br />
        <h1>Dagsplan</h1>
        <br />

        <div className="timeline-wrapper">
        <Timeline sx={{
            [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
            },
        }}>
            <TimelineElement
                time="13:00"
                text="Dørene åpner"
            />
            <TimelineElement
                time="14:00"
                text="Vielse i hagen"
            />
            <TimelineElement
                time="14:15"
                text="Det serveres velkomstdrink og snacks"
            />
            <TimelineElement
                time="15:00"
                text="Vi setter oss til middag"
            />
            <TimelineElement
                time="19:00"
                text="Festgjester ankommer"
            />
            <TimelineElement
                time="19:30"
                text="Kaffe og kaker"
            />
            <TimelineElement
                time="20:00"
                text="Dansegulv og bar åpnes"
            />
            <TimelineElement
                time="23:00"
                text="Lett nattmat"
            />
            <TimelineElement
                time="01:00"
                text="Lokalet stenger"
                last={true}
            />
        </Timeline>

        <div className="info-bilder">
                <img src={bilde_boba} alt="Kjersti og Gaute befarer uteområdet på Nordstrandhuset" />
            </div>
        </div>
    </div>
  );
}
